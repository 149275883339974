@media (max-width: 549px){
  :root {
    --font-size: 16px;
    --h1-size: 56px;
    --h2-size: 32px;
    --h3-size: 28px;
  }
  .scroll-for-more {
    font-size: calc(var(--font-size) * 1.2);
  }
  .pages {
    --font-size: 16px;
  }
  .page1 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    text-align: center;
  }
  .header {
    flex-direction: column;
    align-items: flex-start;
    font-size: var(--h2-size);
    font-weight: var(--h2-font-weight);
    color: var(--default-light-color);
  }
  .logo h2 {
    padding: 0;
    margin: 0;
    font-size: 1em;
    font-weight: inherit;
    color: inherit;
    text-decoration: inherit;
    text-shadow: unset;
  }
  
  .navigation-links {
    padding: 2em 0 0;
    margin: 0;
    text-align: left;
  }
  .navigation-link {
    padding: 0;
    margin: 0;
    display: block;
    font-size: 2em;
    text-shadow: unset;
  }
  
  .site-title-section {
    padding-left: 1em;
    padding-top: 0;
    margin-top: auto;
    margin-left: unset;
    margin-right: unset;
    margin-bottom: 5vh;
    width: 99vw;
    text-align: left;
    color: #fff;
  }

  .site-title-section h1 {
    padding: unset;
    margin: unset;
    font-size: 3em;
    text-shadow: unset;
  }

  .site-title-section h2 {
    padding: unset;
    margin: unset;
    margin-left: 1em;
    margin-bottom: 1em;
    font-size: 2em;
  }
  
  .site-title-section p {
    font-size: 1.2em;
  }
  .about {
    flex-direction: column;
    align-items: flex-start;
  }

  .about-section {
    text-align: center;
    border-bottom: 1px solid #eee;
  }

  .about-section h2 {
    margin: 0;
  }

  .about-left-section {
    width: 100%;
    height: unset;
    flex-direction: column;
    align-items: flex-start;
  }

  .about-left-section .about-section:first-child {
    padding-top: 0;
    padding-bottom: 0;
    text-align: center;
  }

  .about-left-section .about-section:not(:first-child) {
    padding-top: 0;
  }

  .about-left-section .about-section:not(:first-child).active {
    padding: .4em;
  }

  .about-left-section .about-section:not(:first-child):not(.active) {
    padding: 0;
  }

  .about-left-section .about-section:first-child h2 {
    padding: .6em 0;
    font-size: clamp(16px, 2.7vw, 24px);
  }

  .about-left-section .about-section:not(:first-child) h2 {
    padding: .2em 0;
    font-size: clamp(16px, 2.7vw, 24px);
  }

  .about-right-section {
    width: 100%;
  }
  .about-right-section h1 {
      padding-top: .2em;
      padding-bottom: .2em;
      display: block;
      line-height: 1.2em;
      text-align: center;
      font-size: var(--h1-size);
      font-size: calc(var(--h1-size) - .4em );
  }

  .about-right-section-text {
    border-top-right-radius: 0;
  }

  .gallery-header {
    border-bottom: none;
  }

  .grid-cell {
      /*height: 150px;*/
  }
}