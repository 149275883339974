/*1025px and up */
@media (min-width: 1025px) {
  :root {
    --font-size: 18px;
    --h1-size: 72px;
    --h2-size: 40px;
    --h3-size: 24px;
  }
  .pages {
    --font-size: 18px;
  }
  .site-title-section h1 {
    font-size: calc(var(--h1-size) + 16px);
  }
  .site-title-section p {
    font-size: calc(var(--font-size) + 8px);
  }
  .grid-cell {
    height: 250px;
    /*flex-shrink: 1;*/
  }
}

/*Extra large*/
@media (min-width: 1500px){
  :root {
    --font-size: 20px;
    --h1-size: 80px;
    --h2-size: 48px;
    --h3-size: 32px;
  }
  .pages {
    --font-size: 20px;
  }
  .site-title-section h1 {
    font-size: calc(var(--h1-size) + 32px);
  }
  .site-title-section p {
    font-size: calc(var(--font-size) + 16px);
}
}