/*Small Tablets: 500px - 800px*/
@media (min-width: 550px) {
  :root {
    --font-size: 16px;
    --h1-size: 60px;
    --h2-size: 40px;
    --h3-size: 32px;
  }

  .scroll-for-more {
    font-size: calc(var(--font-size) * 2);
  }

  .pages {
    --font-size: 16px;
  }

  .site-title-section {
    width: 75vw;
  }

  .site-title-section h1 {
    font-size: 4.5em;
  }

  .site-title-section p {
    font-size: 1.5em;
  }

  .about {
    flex-direction: column;
    align-items: flex-start;
  }

  .about-section {
    text-align: center;
  }

  .about-section h2 {
    margin: 0;
  }

  .about-left-section {
    width: 100%;
    height: unset;
    flex-direction: row;
    align-items: flex-start;
  }

  .about-left-section .about-section:first-child {
    padding-top: 0;
    padding-bottom: 0;
    text-align: center;
  }

  .about-left-section .about-section:not(:first-child) {
    padding-top: 0;
  }

  .about-left-section .about-section:not(:first-child).active {
    padding: 0;
  }

  .about-left-section .about-section:not(:first-child):not(.active) {
    padding: 0;
  }

  .about-left-section .about-section:first-child h2 {
    padding: 1em 0;
    font-size: clamp(16px, 2.7vw, 24px);
  }

  .about-left-section .about-section:not(:first-child) h2 {
    padding: 1em 0;
    font-size: clamp(16px, 2.7vw, 24px);
  }

  .about-right-section {
    width: 100%;
  }

  .about-right-section h1 {
    display: block;
  }
  

  .about-right-section-text {
    border-top-right-radius: 0;
  }

  .gallery-header {
    border-bottom: .1em solid var(--default-light-color);
  }

  .grid-cell {
    /*height: 250px;*/
  }
}

/*Large Tablets: 800px - 1024*/
@media (min-width: 800px) {
  :root {
    --font-size: 16px;
    --h1-size: 64px;
    --h2-size: 32px;
    --h3-size: 16px;
  }

  .pages {
    --font-size: 16px;
  }

  .site-title-section {
    width: 75vw;
  }

  .site-title-section h1 {
    font-size: 4.5em;
  }

  .site-title-section p {
    font-size: 1.5em;
  }

  .about {
    flex-direction: row;
  }

  .about-section {
    text-align: left;
    border-bottom: none;
  }

  .about-section h2 {
    margin: .5rem;
  }

  .about-left-section {
    padding: 0;
    width: 16%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .about-left-section .about-section:first-child {
    padding-top: 2vh;
    padding-bottom: 2vh;
    text-align: left;
  }

  .about-left-section .about-section:not(:first-child) {
    padding-top: 1.5vh;
  }

  .about-left-section .about-section:first-child h2 {
    padding: 0;
    font-size: clamp(24px, 2.7vw, 48px);
  }

  .about-left-section .about-section:not(:first-child) h2 {
    padding: 0;
    font-size: clamp(16px, 2vw, 36px);
  }

  .about-right-section {
    width: 84%;
  }

  .about-right-section h1 {
    padding: 3vh 4.5vw 0vh 2.5vw;
    margin-top: 0;
    display: inline-block;
    line-height: 1;
    text-align: start;
    font-size: var(--h1-size);
  }

  .about-right-section-text {
    border-top-right-radius: 20px;
  }

  .grid-cell {
    /*height: 250px;*/
  }
}